import '../components/projects-grid.less'
import '../pages/index.less'
import Layout from '../components/layout'
import PaginatedProjects from '../components/paginated-projects'
import React from 'react'
import get from 'lodash/get'
import xorBy from 'lodash/xorBy'
import { SEO } from '../components/seo'
import { graphql } from 'gatsby'
import { richTextToHtml } from '../utils/richTextToHtml'

const IndexPage: React.FC<{ data: unknown }> = props => {
    const siteMetadata = get(props, 'data.siteMetadata')
    //all the projects that are not set to appear on the homePage
    const otherProjects =
        xorBy(
            siteMetadata.homePageProjects,
            get(props, 'data.allCmsProject.nodes'),
            'id'
        ) || []
    return (
        <Layout>
            <SEO siteMetadata={siteMetadata} />
            <div className="hero_article">
                <h1>{siteMetadata.heroTitle}</h1>
                {richTextToHtml(siteMetadata.heroDescription)}
            </div>
            <PaginatedProjects
                firstPageProjects={siteMetadata.homePageProjects}
                paginatedProjects={otherProjects}
                projectsOnPage={12}
                displayShowLessButton
                loadingDelay={0.5}
            />
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
    query {
        siteMetadata: cmsSiteMetadata {
            title
            description
            image {
                publicURL
                url
            }
            twitterCreator
            heroTitle
            heroDescription
            homePageProjects {
                id
                defaultTitle
                heroTitle
                cardTitle
                cardSubtitle
                slug
                themeColor
                themeVariant
                externalUrl
                mobileExternalUrl
                ctaButtonLabel
                cardImage {
                    childImageSharp {
                        gatsbyImageData(width: 320)
                    }
                }
                heroSubtitle
                directExternalLink
            }
        }
        allCmsProject {
            nodes {
                id
                defaultTitle
                heroTitle
                cardTitle
                cardSubtitle
                slug
                themeColor
                themeVariant
                externalUrl
                mobileExternalUrl
                directExternalLink
                ctaButtonLabel
                cardImage {
                    childImageSharp {
                        gatsbyImageData(width: 320)
                    }
                }
                heroSubtitle
            }
        }
    }
`
