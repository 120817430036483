import ProjectCard from '../components/project-card'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import queryString from 'query-string'
import * as styles from './project-card.module.less'
import { Button, ProgressCircle } from '@adobe/react-spectrum'

interface PaginatedProjectsProps {
    firstPageProjects: Array<any>
    paginatedProjects: Array<any>
    projectsOnPage: number
    displayShowLessButton?: boolean
    loadingDelay: number
}

export default function PaginatedProjects(props: PaginatedProjectsProps) {
    const numberOfPages =
        Math.ceil(props.paginatedProjects.length / props.projectsOnPage) +
        (props.firstPageProjects.length > 0 ? 1 : 0)
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const checkHashAndSetPage = () => {
        const hashPage = queryString.parse(location.hash).page as string
        const initialPage = parseInt(hashPage || '0')
        setCurrentPage(initialPage)
    }

    useEffect(() => {
        // if not already added, add the showOnPage attribute to every item of the paginatedProjects Array

        window.addEventListener('hashchange', checkHashAndSetPage, false)
        checkHashAndSetPage()

        return () => {
            window.removeEventListener('hashchange', checkHashAndSetPage, false)
        }
    }, [])

    const showNextPageProjects = () => {
        const nextPage = currentPage + 1
        if (props.loadingDelay > 0) {
            setIsLoading(true)
            setTimeout(() => {
                setCurrentPage(nextPage)
                setIsLoading(false)
                window.location.hash = 'page=' + nextPage
            }, props.loadingDelay * 1000)
        } else {
            setCurrentPage(nextPage)
            window.location.hash = 'page=' + nextPage
        }
    }
    const showPreviousPageProjects = () => {
        const previousPage = currentPage - 1
        if (props.loadingDelay > 0) {
            setIsLoading(true)
            setTimeout(() => {
                setCurrentPage(previousPage)
                setIsLoading(false)
                window.location.hash = 'page=' + previousPage
            }, props.loadingDelay * 1000)
        } else {
            setCurrentPage(previousPage)
            window.location.hash = 'page=' + previousPage
        }
    }
    const getPageNumber = index => {
        const pageNumber = Math.ceil((index + 1) / props.projectsOnPage)
        if (props.firstPageProjects.length > 0) {
            return pageNumber
        } else {
            return pageNumber - 1
        }
    }

    const moreButtonVisibility = () => {
        return currentPage == numberOfPages - 1 ? false : true
    }
    const lessButtonVisibility = () => {
        return currentPage == 0 || !props.displayShowLessButton ? false : true
    }

    // Filtering projects that should be rendered on this page
    const paginatedProjects = props.paginatedProjects.filter(
        (_, idx) => getPageNumber(idx) <= currentPage
    )

    return (
        <div className="projects_grid">
            {props.firstPageProjects.map(project => (
                <ProjectCard key={project.slug} {...project} />
            ))}
            {paginatedProjects.map(project => (
                <ProjectCard key={project.slug} {...project} />
            ))}
            <div
                className={classNames(
                    styles.card,
                    styles.loadmore,
                    !moreButtonVisibility() &&
                        !lessButtonVisibility() &&
                        styles.hidden
                )}
            >
                <div className={classNames(isLoading && styles.hidden)}>
                    <Button
                        variant="cta"
                        onPress={showNextPageProjects}
                        UNSAFE_className={classNames(
                            !moreButtonVisibility() && styles.hidden
                        )}
                    >
                        More projects
                    </Button>
                    <Button
                        variant="secondary"
                        onPress={showPreviousPageProjects}
                        marginStart={
                            currentPage != numberOfPages ? '16px' : undefined
                        }
                        UNSAFE_className={classNames(
                            !lessButtonVisibility() && styles.hidden
                        )}
                    >
                        Less projects
                    </Button>
                </div>
                <div className={classNames(!isLoading && styles.hidden)}>
                    <ProgressCircle
                        aria-label="loading projects"
                        size="L"
                        isIndeterminate
                    ></ProgressCircle>
                </div>
            </div>
        </div>
    )
}
